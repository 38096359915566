import { Button, CardMedia, Grid2, Link, Stack, Typography, useMediaQuery } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';

function Footer() {
  const location = useLocation();
  const navigate = useNavigate();

  const upMd = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const isBrandPartners = location.pathname.startsWith('/brand-partners');

  const footerMenus = [
    {
      title: 'Community Members',
      links: [
        {
          label: 'Create',
          href: '/#'
        },
        {
          label: 'Earn',
          href: '/#earn'
        },
        {
          label: 'Discover',
          href: '/#discover'
        },
        {
          label: 'Shopping',
          href: '/#shopping'
        },
        {
          label: 'Transparency',
          href: '/#transparency'
        }
      ]
    },
    {
      title: 'Brand Partners',
      links: [
        {
          label: 'Conversion',
          href: '/brand-partners#conversion'
        },
        {
          label: 'Exposure',
          href: '/brand-partners#exposure'
        },
        {
          label: 'Engagement',
          href: '/brand-partners#engagement'
        },
        {
          label: 'Create',
          href: '/brand-partners#'
        }
      ]
    },
    {
      title: "Let's Connect",
      links: []
    }
  ];

  return (
    <Grid2
      component="section"
      id="footer"
      container
      sx={(theme) => ({
        minHeight: '390px',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('xl')]: {
          minHeight: '293px'
        },
        [theme.breakpoints.down('lg')]: {
          minHeight: '208px'
        },
        [theme.breakpoints.down('md')]: {
          minHeight: '100vh',
          justifyContent: 'flex-start',
          alignItems: 'flex-start'
        }
      })}
    >
      <Grid2
        component="footer"
        container
        size={12}
        sx={(theme) => ({
          height: 1,
          color: '#FFF',
          alignItems: 'center',
          mx: '166px',
          [theme.breakpoints.down('xl')]: {
            mx: '120px'
          },
          [theme.breakpoints.down('lg')]: {
            mx: '85px'
          },
          [theme.breakpoints.down('md')]: {
            mt: '46px',
            mx: '25px'
          }
        })}
      >
        <Grid2 container size={12} flexDirection={{ xs: 'column', md: 'row' }} spacing={{ xs: 5, md: 4 }}>
          <Grid2 container size={{ xs: 12, md: 5 }} flexDirection="column" rowSpacing={{ xs: 2.5, lg: 3.75, xl: '35px' }}>
            <Grid2 size={12}>
              <Stack spacing={{ md: 1.25, lg: 2 }}>
                {upMd && (
                  <CardMedia
                    component="img"
                    src="/logo.png"
                    alt="SHEKR Logo"
                    sx={(theme) => ({
                      width: '159px',
                      height: '40px',
                      [theme.breakpoints.down('xl')]: {
                        width: '119.25px',
                        height: '30.076px'
                      },
                      [theme.breakpoints.down('lg')]: {
                        width: '67px',
                        height: '17px'
                      }
                    })}
                  />
                )}
                <Typography
                  sx={(theme) => ({
                    fontSize: 20,
                    lineHeight: '30px',
                    [theme.breakpoints.down('xl')]: {
                      fontSize: 15,
                      lineHeight: '24px'
                    },
                    [theme.breakpoints.down('lg')]: {
                      fontSize: 12,
                      lineHeight: '22px'
                    },
                    [theme.breakpoints.down('md')]: {
                      fontSize: 14
                    },
                    [theme.breakpoints.down('sm')]: {
                      width: '280px'
                    }
                  })}
                >
                  The social media that gives back to its community
                </Typography>
              </Stack>
            </Grid2>
            <Grid2 size={12}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M7.39289 11.1472V7.7186C7.39187 6.65579 7.76934 5.63049 8.45202 4.84175C9.1347 4.05301 10.0739 3.55709 11.0873 3.45028C12.1006 3.34347 13.1159 3.63338 13.936 4.26373C14.756 4.89408 15.3224 5.8199 15.525 6.86146M5.75003 11.1472H17.25C18.1574 11.1472 18.8929 11.9147 18.8929 12.8615V18.8615C18.8929 19.8082 18.1574 20.5757 17.25 20.5757H5.75003C4.84271 20.5757 4.10718 19.8082 4.10718 18.8615V12.8615C4.10718 11.9147 4.84271 11.1472 5.75003 11.1472Z"
                    />
                  </svg>
                }
                sx={(theme) => ({
                  width: '351px',
                  height: '69.967px',
                  fontSize: 18,
                  lineHeight: 'normal',
                  '& .MuiButton-icon': {
                    mr: 1.25,
                    svg: {
                      width: '24px',
                      height: '24px'
                    }
                  },
                  [theme.breakpoints.down('xl')]: {
                    width: '225px',
                    height: '45px',
                    fontSize: 15,
                    '& .MuiButton-icon svg': {
                      width: '18px',
                      height: '18px'
                    }
                  },
                  [theme.breakpoints.down('lg')]: {
                    width: '170px',
                    height: '40px',
                    fontSize: 12,
                    '& .MuiButton-icon svg': {
                      width: '16px',
                      height: '16px'
                    }
                  }
                })}
                onClick={() => navigate(isBrandPartners ? '/brand-partners/apply-store' : '/unlock-early-access')}
              >
                {isBrandPartners ? 'Apply for a store' : 'Unlock early access'}
              </Button>
            </Grid2>
          </Grid2>
          <Grid2
            container
            size={{ xs: 12, md: 7 }}
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent={{ md: 'flex-end' }}
            columnSpacing={{ md: '54px', lg: '76px', xl: '102px' }}
            rowSpacing={{ xs: 5, md: 0 }}
          >
            {footerMenus.map((footerMenu, index) => (
              <Stack key={index} spacing={{ xs: 1.25, md: '11px', lg: '17px', xl: '23px' }}>
                <Typography
                  variant="h3"
                  sx={(theme) => ({
                    fontSize: 20,
                    fontWeight: 400,
                    lineHeight: '30px',
                    [theme.breakpoints.down('xl')]: {
                      fontSize: 15
                    },
                    [theme.breakpoints.down('lg')]: {
                      fontSize: 12
                    },
                    [theme.breakpoints.down('md')]: {
                      fontSize: 14
                    }
                  })}
                >
                  {footerMenu.title}
                </Typography>
                <Stack spacing={{ xs: 1.25, lg: '15px', xl: 3 }}>
                  {footerMenu.links.map((menu, mIndex) => (
                    <Link
                      key={mIndex}
                      href={menu.href}
                      sx={(theme) => ({
                        fontSize: 20,
                        fontWeight: 700,
                        color: '#FFF',
                        lineHeight: 'normal',
                        textDecoration: 'none',
                        [theme.breakpoints.down('xl')]: {
                          fontSize: 15
                        },
                        [theme.breakpoints.down('lg')]: {
                          fontSize: 12
                        },
                        [theme.breakpoints.down('md')]: {
                          fontSize: 14
                        }
                      })}
                    >
                      {menu.label}
                    </Link>
                  ))}
                </Stack>
              </Stack>
            ))}
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
}

export default Footer;
