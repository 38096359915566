import { useMediaQuery, useTheme } from '@mui/material';
import { Dispatch, ReactElement, SetStateAction, createContext, useEffect, useState } from 'react';

const CustomContext = createContext<{
  scrollPage: number;
  setScrollPage: Dispatch<SetStateAction<number>>;
  isLightBg: boolean;
  setIsLightBg: Dispatch<SetStateAction<boolean>>;
  sections: HTMLElement[];
  setSections: Dispatch<SetStateAction<HTMLElement[]>>;
} | null>(null);

export const CustomProvider = ({ children }: { children: ReactElement }) => {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down('md'));

  const [scrollPage, setScrollPage] = useState(1);
  const [isLightBg, setIsLightBg] = useState(false);
  const [sections, setSections] = useState<HTMLElement[]>([]);

  useEffect(() => {
    if (sections[scrollPage - 1] && window.getComputedStyle(sections[scrollPage - 1]).backgroundColor) {
      const isFooter = sections[scrollPage - 1].id === 'footer';
      const color =
        isFooter && !downMd
          ? sections[scrollPage - 2]
            ? theme.palette.getContrastText(window.getComputedStyle(sections[scrollPage - 2]).backgroundColor)
            : '#fff'
          : theme.palette.getContrastText(window.getComputedStyle(sections[scrollPage - 1]).backgroundColor);

      setIsLightBg(color !== '#fff');
    }
  }, [downMd, scrollPage, sections, theme.palette]);
  // useEffect(() => {
  //   setIsLightBg(scrollPage >= 2 && scrollPage <= 6); // or adjust condition as needed
  // }, [scrollPage]);

  return (
    <CustomContext.Provider
      value={{
        scrollPage,
        setScrollPage,
        isLightBg,
        setIsLightBg,
        sections,
        setSections
      }}
    >
      {children}
    </CustomContext.Provider>
  );
};

export default CustomContext;
