import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';
import Loadable from 'components/Loadable';
import ScrollablePage from 'layout/ScrollablePage';

const CommunityMembers = Loadable(lazy(() => import('pages/community-members')));
const UnlockEarlyAccess = Loadable(lazy(() => import('pages/community-members/unlock-early-access')));

const BrandPartners = Loadable(lazy(() => import('pages/brand-partners')));
const ApplyStore = Loadable(lazy(() => import('pages/brand-partners/apply-store')));

const ContactUs = Loadable(lazy(() => import('pages/contact-us')));

const Error404 = Loadable(lazy(() => import('pages/Error404')));

function Routes() {
  return useRoutes([
    {
      path: '/',
      children: [
        {
          path: '/',
          element: <ScrollablePage />,
          children: [
            {
              path: '/',
              children: [
                {
                  index: true,
                  element: <CommunityMembers />
                },
                {
                  path: 'unlock-early-access',
                  element: <UnlockEarlyAccess />
                }
              ]
            },
            {
              path: '/brand-partners',
              children: [
                {
                  index: true,
                  element: <BrandPartners />
                },
                {
                  path: 'apply-store',
                  element: <ApplyStore />
                }
              ]
            },
            {
              path: '/contact-us',
              element: <ContactUs />
            }
          ]
        }
      ]
    },
    {
      path: '*',
      element: <Error404 />
    }
  ]);
}

export default Routes;
