import { useRef, useEffect } from 'react';
import useCustomContext from 'hooks/useCustomContext';
import Header from './Header';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';
import { Box, Grid2 } from '@mui/material';

const ScrollablePage = () => {
  const { setScrollPage, setSections } = useCustomContext();

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  //   const classNavSpan = isLightBg
  //     ? 'absolute left-0 w-0 h-1 top-9 bg-white transition-all duration-300 ease-in-out group-hover:w-full'
  //     : 'absolute left-0 w-0 h-1 top-9 bg-white transition-all duration-300 ease-in-out group-hover:w-full';

  // Update page based on the section in view
  useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    let intersectionObserver: IntersectionObserver;

    const setupIntersectionObserver = () => {
      const sections = container.querySelectorAll<HTMLElement>('section');
      setSections(Array.from(sections));

      if (intersectionObserver) {
        intersectionObserver.disconnect();
      }

      intersectionObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const index = Array.from(sections).indexOf(entry.target as HTMLElement);
              setScrollPage(index + 1);
            }
          });
        },
        { root: container, threshold: 0.5, rootMargin: '1px' }
      );

      sections.forEach((section) => intersectionObserver.observe(section));
    };

    // Watch for DOM changes
    const mutationObserver = new MutationObserver(() => {
      setupIntersectionObserver();
    });

    // Start observing the container for DOM changes
    mutationObserver.observe(container, {
      childList: true, // Watch for section elements being added/removed
      subtree: true // Watch nested elements too
    });

    // Initial setup
    setupIntersectionObserver();

    return () => {
      mutationObserver.disconnect();
      intersectionObserver?.disconnect();
      setSections([]);
    };
  }, [setScrollPage, setSections]);

  return (
    <Grid2
      container
      flexDirection="column"
      sx={{ position: 'relative', minHeight: '100vh', bgcolor: 'primary.main', color: 'secondary.lighter' }}
    >
      <Header />

      {/* MAIN SCROLLABLE CONTENT */}
      <Box component="main" sx={{ width: 1, display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <Box ref={scrollContainerRef} sx={{ height: '100vh', scrollSnapType: 'y mandatory', scrollBehavior: 'smooth', overflowY: 'auto' }}>
          <Outlet />
          <Footer />
        </Box>
      </Box>
    </Grid2>
  );
};

export default ScrollablePage;
