// project-imports
import Default from './default';

// types
import { PaletteThemeProps } from 'types/theme';
import { ThemeMode, PresetColor } from 'types/config';

// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //

const Theme = (presetColor: PresetColor, mode: ThemeMode): PaletteThemeProps => {
  switch (presetColor) {
    // case 'theme1':
    //   return Theme1(mode);
    // case 'theme2':
    //   return Theme2(mode);
    // case 'theme3':
    //   return Theme3(mode);
    // case 'theme4':
    //   return Theme4(mode);
    // case 'theme5':
    //   return Theme5(mode);
    // case 'theme6':
    //   return Theme6(mode);
    // case 'theme7':
    //   return Theme7(mode);
    // case 'theme8':
    //   return Theme8(mode);
    default:
      return Default(mode);
  }
};

export default Theme;
