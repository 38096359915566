export type MenuItem = {
  id: string;
  label: string;
  href: string;
  children?: MenuItem[];
};

const menuItems: { items: MenuItem[] } = {
  items: [
    {
      id: 'community-members',
      label: 'Community Members',
      href: '/',
      children: [
        {
          id: 'unlock-early-access',
          label: 'Unlock Early Access',
          href: '/unlock-early-access'
        }
      ]
    },
    {
      id: 'brand-partners',
      label: 'Brand Partners',
      href: '/brand-partners',
      children: [
        {
          id: 'apply-store',
          label: 'Apply For A Store',
          href: '/brand-partners/apply-store'
        }
      ]
    },
    {
      id: 'contact-us',
      label: "Let's Connect",
      href: '/contact-us'
    }
  ]
};

export default menuItems;
