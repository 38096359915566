import React from 'react';
import { CustomProvider } from 'contexts/CustomContext';
import Routes from 'routes';
import ThemeCustomization from 'themes';

const App: React.FC = () => {
  return (
    <ThemeCustomization>
      <CustomProvider>
        <Routes />
      </CustomProvider>
    </ThemeCustomization>
  );
};

export default App;
